import { useQuery } from '@tanstack/react-query';

import type { Category } from '~/db/schema';

import { fetchCategories } from '~/api/category';

const useFetchCategories = (initialData?: Category[]) => {
  const query = useQuery({ queryKey: ['categories'], queryFn: fetchCategories, initialData });

  return query;
};

export default useFetchCategories;
